import { useQuery } from '@tanstack/react-query';
import { createdInPOS } from '../../services/api';

export function useCreatedInPOS({
  orderID,
  paymentSuccess,
}) {
  const {
    data: orderInPOS,
    isLoading: isLoadingOrderInPOS,
    isError,
    isFetching,
    error,
  } = useQuery(
    ['createdInPOS', orderID],
    () => createdInPOS(orderID),
    {
      enabled: paymentSuccess,
      retry: (c, err) => err.response?.status !== 401,
      refetchInterval: (response) => {
        if (!response) {
          return false;
        }

        if (!response?.is_created_in_pos) {
          return 1000;
        }

        return false;
      },
    },
  );

  return {
    isLoadingOrderInPOS,
    orderInPOS,
    isError,
    isFetching,
    isAuthError: error?.response?.status === 401,
  };
}

export default useCreatedInPOS;
