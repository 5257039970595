import clsx from 'clsx';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../components/Button';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  // eslint-disable-next-line comma-dangle
  LogoBacuIcon
} from '../components/icons';
import PaymentStatusPopUp from '../components/PaymentStatusPopUp';
import StoreBasic from '../components/StoreBasic';
import config from '../config';
import { useDelivery } from '../hooks/actions/useDelivery';
import { useOrderStatus } from '../hooks/services/useOrder';
import { useAmplitude } from '../hooks/useAmplitude';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics';
import { formatCurrency } from '../utils/currency';
import { getPaymentTypeName } from '../utils/string';
import HelpButton from './components/HelpButton';
import OrderStatusDelivery from './Delivery/OrderStatusDelivery';

function OrderStatus() {
  const [showModal, setShowModal] = useState(true);
  const [modalStatus, setModalStatus] = useState('LOADING');
  const { orderID } = useParams();
  const [showDetails, setShowDetails] = useState(true);

  const { trackPaymentFlowResult } = useAmplitude().useCheckoutPageEvents();

  const { order } = useOrderStatus({
    orderID,
    status: modalStatus,
    onStatusChange: setModalStatus,
    onCloseModal: () => setShowModal(false),
  });

  const { deliveryData } = useDelivery();
  const deliveryflag = deliveryData.isDelivery;
  const navigate = useNavigate();
  const { sendEvent } = useGoogleAnalytics();

  useEffect(() => {
    trackPaymentFlowResult(orderID, order.transaction_status);
  }, [order.transaction_status]);

  const transactionSuccess = order.transaction_status === config.paymentStatus.SUCCEEDED;

  if (order && transactionSuccess && (deliveryflag || order.type === config.deliveryType.delivery)) {
    return (
      <OrderStatusDelivery order={order} />
    );
  }

  sendEvent('checkout end', {
    transaction_status: order.transaction_status,
    email: order.customer?.email,
  });

  const handlePaymentStatus = () => {
    switch (order.transaction_status) {
      case config.paymentStatus.SUCCEEDED:
        setShowModal(false);
        break;
      case config.paymentStatus.ERROR:
        navigate(`/store/${order.store?.storeId}/cart?step=2`);
        break;
      case config.paymentStatus.DECLINED:
      case config.paymentStatus.REJECTED:
        navigate(`/store/${order.store?.storeId}/cart?step=2`); // TODO: Temporally return to step 2
        break;
      default:
        navigate('/order/search');
    }
  };

  const handleToggleDetails = () => setShowDetails((prev) => !prev);
  const handlePickUpOrder = () => navigate(`/order/${order.order_id}/rating`);
  const handleOpenMap = ({ lat, lng }) => (
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}&zoom=20`, '_blank')
  );

  const pickup = order?.pickUpDate ? new Date(order?.pickUpDate) : new Date();
  const customerPayer = order.paymentDetails?.dataPayment?.customer_payer;

  return (
    <div
      className="absolute bg-baco-primary top-0 bottom-0 w-screen px-4 pt-12 h-full overflow-y-auto overflow-x-hidden"
    >
      <LogoBacuIcon
        className="relative w-[129px] h-[78px] fill-white m-auto mb-8"
      />

      <div className="w-full max-w-4xl mx-auto">
        <h2 className="text-white text-3xl mt-0 mb-5 font-medium">
          ¡Hemos recibido tu pedido!
          <br />
          Estará listo para recoger a las:
        </h2>

        <div
          className="flex justify-center w-full mb-2"
        >
          <h2 className="text-baco-yellow text-3xl mt-0 font-bold lowercase">
            {format(pickup, 'hh:mma')}
          </h2>
        </div>
        <hr className="bg-baco-yellow h-1 w-full border-none mb-7" />
        <div className="text-white text-sm lg:text-base text-center font-bold leading-6 mb-7">
          <p>Recuerda, Pick Up es para disfrutar fuera del punto.</p>
          <p>En tu casa, tu oficina, para el brunch o la cena.</p>
        </div>
        <div className="bg-baco-secondary-100 rounded-lg py-6 px-4 mb-10">
          <h3 className="text-baco-primary text-xl font-bold mb-6 mt-0">
            Punto de Recogida
          </h3>
          <StoreBasic
            {...(order?.store || {})}
            imageUrl={order?.store?.pickup_imageUrl}
            onClick={handleOpenMap}
          />

          <p className="text-baco-primary text-base font-normal leading-6 tracking-wide mb-6">
            Una vez que llegues al Punto de Recogida, acércate a la zona de Pick-Up, busca tu pedido, y ¡listo!
          </p>

          <hr className="border-none h-[1px] w-full bg-baco-primary my-4" />

          <h3 className="text-baco-primary text-xl font-bold mb-6 mt-0">
            Recuerda
          </h3>

          <p className="text-baco-primary text-base font-normal leading-6 tracking-wide mb-6">
            Tu pedido estará listo a la hora indicada. Te recomendamos llegar máximo 10 minutos
            después de esta hora para no perder la calidad de tus productos.
          </p>

          <Button size="lg" fullWidth onClick={handlePickUpOrder}>
            Pedido recogido
          </Button>
        </div>

        <div
          className={
            clsx('transition-all overflow-hidden', {
              'max-h-[270px]': !showDetails,
              'max-h-full': showDetails,
            })
          }
        >
          <h2
            className="text-lg text-white font-bold mb-4"
          >
            Datos Registrados
          </h2>

          <ul className="text-white text-sm mb-6">
            <li className="flex justify-start capitalize mb-4">
              <span>Nombre:</span>
              <strong className="pl-2">
                {`${customerPayer?.first_name} ${customerPayer?.last_name}`}
              </strong>
            </li>
            <li className="flex justify-start mb-4">
              <span>Correo:</span>
              <strong className="pl-2">
                {customerPayer?.email}
              </strong>
            </li>
            <li className="flex justify-start mb-4">
              <span>Número Celular:</span>
              <strong className="pl-2">
                {customerPayer?.phone?.country_code}
                {customerPayer?.phone?.number}
              </strong>
            </li>
            <li className="flex justify-start gap-2 mb-4">
              <span>Últimos digitos de la orden:</span>
              <strong className="pl-2">
                {(order.paymentDetails?.dataPayment?.transactions?.id || '').substr(-4)}
              </strong>
            </li>
            <li className="flex justify-start mb-4">
              <span>Método de Pago:</span>
              <strong className="pl-2">
                {getPaymentTypeName(order.paymentDetails?.dataPayment?.transactions?.payment_method?.type)}
              </strong>
            </li>
          </ul>

          <h2
            className={
              clsx('flex justify-between items-center w-full', {
                'mb-6': showDetails,
                'mb-3': !showDetails,
              })
            }
          >
            <span className="text-lg text-white font-bold">
              Detalles del Pedido
            </span>
            <button
              className="text-white"
              onClick={handleToggleDetails}
              type="button"
            >
              {
                showDetails
                  ? (
                    <span className="flex justify-start items-center gap-2">
                      ver menos
                      <ChevronUpIcon className="h-6 w-6" />
                    </span>
                  )
                  : (
                    <span className="flex justify-start items-center gap-2">
                      ver más
                      <ChevronDownIcon className="h-6 w-6" />
                    </span>
                  )
              }
            </button>
          </h2>

          <ul className="text-white text-sm mb-6">
            {order?.items?.map((item) => (
              <li key={item.product?.id} className="flex flex-col justify-between mb-4">
                <span className="flex justify-between mb-2">
                  <strong className="w-10">
                    {`${item.quantity}x`}
                  </strong>
                  <span className="w-full">
                    {item.product?.name}
                  </span>
                </span>
                <ul className="pl-10">
                  {(item.itemGroups || [])
                    .flatMap((itemGroup) => itemGroup.modifiers)
                    .map((modifier) => (
                      <li key={modifier.id} className="flex justify-between mb-2">
                        <strong className="w-10">
                          {`${modifier.quantity}x`}
                        </strong>
                        <span className="w-full">
                          {modifier.name}
                        </span>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
            <li className="flex justify-end pt-2 border-t-2 border-t-white">
              <span>
                Total
              </span>
              <strong className="pl-2">
                {formatCurrency(order.paymentDetails?.dataPayment?.amount?.value || 0)}
              </strong>
            </li>
          </ul>
        </div>

        <HelpButton
          className="mt-6 mb-12 font-semibold"
          style={{
            backgroundColor: 'transparent',
            hover: 'transparent',
            active: 'transparent',
          }}
        />

        {showModal && (
          <PaymentStatusPopUp
            status={modalStatus}
            onClick={handlePaymentStatus}
          />
        )}
      </div>
    </div>
  );
}

export default OrderStatus;
